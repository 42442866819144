import actions from './actions';
import HSBBClient from '@helpers/apiClient';

const initState = {
  accessToken: null,
  user: null,
  userSetting: { roles: [] },
  userRole: null,
  appStatus: 'checkingAuth',
  loginError: null,
  userData: null
};

const saveLoginTime = () => {
  if (document.referrer.includes('hsbbbo')) {
    HSBBClient.setLastLogin();
  }
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      saveLoginTime();
      return {
        ...state,
        accessToken: action.accessToken
      };

    case actions.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.error,
        appStatus: 'ready'
      };

    case actions.USER_PROFILE_LOADED:
      return {
        ...state,
        user: action.profile,
        userSetting: action.userSetting,
        userData: action.userData,
        appStatus: 'ready'
      };

    case actions.USER_ROLE:
      return {
        ...state,
        userId: action.userId,
        appStatus: 'ready'
      };

    case actions.USER_ROLE_LOADED:
      return {
        ...state,
        userRole: action.userRole,
        rolePosition : action.rolePosition,
        userRoleError: action.userRoleError,
        appStatus: 'ready'
      };

    case actions.USER_ROLE_ERROR:
      return {
        ...state,
        userRoleError: action.error,
        appStatus: 'ready'
      };

    case actions.REDIRECT_TO_LOGIN:
    case actions.LOGOUT:
      return {
        ...initState,
        appStatus: 'ready'
      };

    case actions.REDIRECT:
      return {
        ...state,
        appStatus: 'ready'
      };

    default:
      return state;
  }
}