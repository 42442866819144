import HSBBClient2 from '@helpers/apiClient-v2';

const actions = {
  SET_NOTIFICATION_COUNT: 'SET_NOTIFICATION_COUNT',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION'
  
  // userRole: (userId) => ({
  //   type: actions.USER_ROLE,
  //   userId
  // }),
};

export { actions }
