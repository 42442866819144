import { actions } from './actions';

const initialState = {
  notifications: [],
  count: 0, 
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload.notifications,
        count: action.payload.count
      };
    case actions.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notifications: action.payload.notifications,
        count: action.payload.count,
      };
    default:
      return state;
  }
};
export default notificationReducer;