import React from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/admin">
              <Icon component={() => <img src="/celcomdigi-logo-1.png" alt="" width={'50%'} />} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/admin">
            <Icon component={() => <img src="/celcomdigi-logo.png" alt="" width={'75%'} />} />
          </Link>
        </h3>
      )}
    </div>
  );
};
