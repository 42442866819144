import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Badge, Tabs, Card } from 'antd';
import HSBBClient2 from '@helpers/apiClient-v2';
import { actions, fetchNotificationCount } from '../../redux/notification/actions'
import { store } from '@redux/store';
import { push } from 'connected-react-router';

function TopbarNotification() {
  const dispatch = useDispatch();
  const { userSetting, rolePosition} = useSelector((state) => state.Auth);

  const count = useSelector((state) => state.Notification.count); // Access the count
  const [notificationCount, setNotificationCount] = useState(count)

  async function fetchData(){
    const allNotifications = await HSBBClient2.getNotificationList(userSetting.storeId);
    const allNotification = allNotifications.data.applList;

    // Filter the applications notification based on Access level
    let notification;
    if(userSetting.roles[0] === 'Alternate Channel' && rolePosition === 'Sales Rep'){
      const userApplication = await HSBBClient2.getApplicationsByCreator(userSetting.userId);
      const userApplications = userApplication.data.applList;
      if(userApplications.length > 0)
      {
        const userApplIds = new Set(userApplications.map(obj => obj.session))
        notification = allNotification.filter(obj => userApplIds.has(obj.applId))
        // const userApplicationslist = allNotification.filter(item => userApplications.some(item2 => item.applId === item2.session))
        // notification = userApplicationslist
      }else {
        notification = []
      }
    }
    else{
      notification = allNotification
    }

    const filteNotification = notification.filter((e) => e.updatedBy !== userSetting.userId)
    const unreadNotification =  filteNotification.filter((e) => e.isRead === false)
    setNotificationCount(unreadNotification.length)
    dispatch({
      type: actions.SET_NOTIFICATION_COUNT,
      payload: {
        notifications: filteNotification,
        count : unreadNotification.length, 
      }
    });
  }

  useEffect(() => {
    fetchData()
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
    }, 120000) // 120000 -2mins, 60000- 1mins
    return () => clearInterval(interval)
  }, [])

  // useEffect(() => {
  //   dispatch(fetchNotificationCount()); // Fetch count on mount
  // }, [dispatch]);
  // const handleRemove = (id) => {
  //   dispatch(removeNotification(id));
  // };


  return (
      <div className="isoImgWrapper" onClick={() => dispatch(push(`/admin/notifications`))}>
        <Badge count={count}>
          <Icon type="bell" />
        </Badge>
      </div>
  );
}

export default TopbarNotification;
