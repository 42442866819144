import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import appActions from '@redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarNotification from './topbarNotification'
import TopbarWrapper from './topbar.style';
import themes from '@settings/themes';
import { themeConfig } from '@settings';

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];
const notificationRoles = ['DIGI Dealer', 'DIGI Store Staff', 'DIGI Store Manager', 'Telesales Staff', 'Telesales Manager', 'Alternate Channel', 'Admin']

function Topbar() {
  const user = useSelector((state) => state.Auth.user);
  const userSetting = useSelector((state) => state.Auth.userSetting);
  console.log('userSetting', userSetting)
  const appState = useSelector((state) => state.App);
  const { collapsed } = appState;
  const dispatch = useDispatch();
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70
  };

  return (
    <TopbarWrapper>
      <Header style={styling} className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}>
        <div className="isoLeft">
          <button
            className={collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'}
            style={{ color: customizedTheme.textColor }}
            onClick={() => dispatch(toggleCollapsed())}
          />
        </div>

        <ul className="isoRight">
          {notificationRoles.includes(userSetting.roles[0]) && 
            <li className="isoUser">
              <TopbarNotification />
            </li>
          }

          <li className="isoUser">
            <TopbarUser user={user} />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
}

export default Topbar;
